// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
//import "jquery"
import "bootstrap"
import "chartkick/chart.js"

Rails.start()
Turbolinks.start()
ActiveStorage.start()
var $ = require( "jquery" )
require("slick-carousel")

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

document.addEventListener("turbolinks:load", function() {
    $('.scroller').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    })
})
